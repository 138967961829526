import _uniq from 'lodash.uniq'
import _cloneDeep from 'lodash.clonedeep'
import _remove from 'lodash.remove'
import { formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

import {
  hasTransportationRole as hasTransportationRoleFunc,
  isAuthorized,
  addAlert
} from '../../utilities/helpers'

import store from '../../store'

import {
  D_FIND_TRANSPORTATION_OWNERS,
  D_FIND_TRANSPORTATION_OWNERS_TOTAL,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL,
  D_ARCHIVE_TRANSPORTATION_OWNER,
  D_UNARCHIVE_TRANSPORTATION_OWNER,
  D_DELETE_TRANSPORTATION_OWNER,
  D_FIND_COUNTRY_CURRENCIES, D_SET_TO_USER_CACHE, D_GET_FROM_USER_CACHE, D_GET_TRANSPORTATION_OWNER
} from '../../utilities/action-types'

import { C_SET_SELECTED_TRANSPORTATION_OWNER } from '../../utilities/mutation-types'
import { findTransportationProfiles } from '../../services/transportation-profiles-service'

export default {
  name: 'transportation-accounts',
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      selectedAccount: null,
      searchKeywords: '',
      totalArchivedAccounts: 0,
      totalAccounts: 0,
      accounts: [],
      countries: [],
      selectedCountry: { countryId: 'none' },
      apiInProgress: false,
      selectedAccountTab: 'accounts',
      headers: [],
      archivedHeaders: [],
      loadedAllRequiredAssets: false
    }
  },
  watch: {
    $route (to, from) {
      if (this.forceRootViewRefresh) {
        this.forceRootViewRefresh()
      }
    }
  },
  mounted: function () {
    function onRouterLoad () {
      if (this.$router.currentRoute.name === 'transportation-accounts') {
        this.headers = [
          {
            text: 'Company Name',
            align: 'left',
            sortable: true,
            value: 'companyName'
          },
          {
            text: 'Contact Name',
            align: 'left',
            sortable: true,
            value: 'contactName'
          },
          {
            text: 'Phone',
            align: 'left',
            sortable: true,
            value: 'contactPhone'
          },
          {
            text: 'Email',
            align: 'left',
            sortable: true,
            value: 'contactEmail'
          },
          {
            text: 'Country',
            align: 'left',
            sortable: true,
            value: 'currentCountryId'
          },
          {
            text: 'Date Created',
            align: 'left',
            sortable: true,
            value: 'dateCreated'
          },
          {
            text: 'Date Updated',
            align: 'left',
            sortable: true,
            value: 'dateUpdated'
          },
          { text: 'Actions', value: 'companyName', sortable: false }
        ]

        let archivedHeaders = _cloneDeep(this.headers)
        _remove(archivedHeaders, o => (o.value === 'currentCountryId'))
        archivedHeaders.splice(archivedHeaders.length - 2, 1, {
          text: 'Date Archived',
          align: 'left',
          sortable: true,
          value: 'dateArchived'
        })
        this.archivedHeaders = archivedHeaders

        store.dispatch(D_FIND_COUNTRY_CURRENCIES).then((countries) => {
          this.countries = [{ text: 'None', value: { countryId: 'none' } }].concat(countries.map(o => ({
            text: o.countryLabel,
            value: o
          })))
          this.searchAccounts()
        }).catch((err) => {
          addAlert({
            message: `Error in retrieving country data: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        })
      }
    }

    this.$nextTick(onRouterLoad.bind(this))
    this.selectedAccount = store.getters.getSelectedTransportationOwner

    if (this.selectedAccount) {
      this.loadedAllRequiredAssets = true
    } else {
      store.dispatch(D_GET_FROM_USER_CACHE, 'selectedAccountId').then((accountId) => {
        if (accountId) {
          return store.dispatch(D_GET_TRANSPORTATION_OWNER, { id: accountId }).then((account) => {
            this.setAsSelectedAccount(account)
            this.$props.forceRootViewRefresh()
          })
        }
      }).finally(() => {
        this.loadedAllRequiredAssets = true
      })
    }
  },
  methods: {
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    hasTransportationRole (transportationRole) {
      const { signedInUser } = this.$props
      if (signedInUser) {
        this.transportationAccountUser = this.transportationAccountUser || signedInUser.transportationAccountUsers?.find((o) => (o.transportationAccountId === this.transportationAccountId))
        return hasTransportationRoleFunc(signedInUser, transportationRole, this.$props.transportationAccountId, this.transportationAccountUser)
      }
    },
    setAsSelectedAccount (account) {
      if (account) account.isSelectedAccount = true
      store.commit(C_SET_SELECTED_TRANSPORTATION_OWNER, account)
      store.dispatch(D_SET_TO_USER_CACHE, { key: 'selectedAccountId', value: account.id, expiryInSeconds: null })
      this.selectedAccount = account
      if (account && this.accounts) {
        this.accounts.map(o => { if (o.id !== account.id) o.isSelectedAccount = false })
      }
    },
    clearSelectedAccount () {
      if (this.selectedAccount) {
        this.selectedAccount.isSelectedAccount = false
      }
      this.accounts.map(o => { o.isSelectedAccount = false })
      store.commit(C_SET_SELECTED_TRANSPORTATION_OWNER, null)
      store.dispatch(D_SET_TO_USER_CACHE, { key: 'selectedAccountId', value: null, expiryInSeconds: null })
      this.selectedAccount = null
    },
    loadAccount (account) {
      if (account.isEditable) {
        this.$router.push({ name: 'transportation-account', params: { accountId: account.id } })
      }
    },
    onFilterByCountry (val) {
      this.selectedCountry = val
      this.searchAccounts(this.selectedUserTab)
    },
    archiveAccount (account) {
      if (this.hasUserType('admin')) {
        if (confirm('Confirm archiving this account. The account and all vehicles will be disconnected from any equipment attached. All vehicles will also be inaccessible')) {
          account.isArchiveInProgress = true
          store.dispatch(D_ARCHIVE_TRANSPORTATION_OWNER, {
            id: account.id
          }).then(() => {
            this.searchAccounts(this.selectedAccountTab)
            addAlert({
              message: `Successfully archived account ${account.companyName}`,
              type: 'success',
              transient: true
            })
          }).catch((err) => {
            console.log(err)
            addAlert({
              message: `Error in archiving account: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }).finally(() => {
            account.isArchiveInProgress = false
          })
        }
      }
    },
    undoArchiveAccount (account) {
      if (this.hasUserType('admin')) {
        if (confirm('Un-archiving this account will allow it to be accessible by the owner, all existing drivers and passengers')) {
          account.isArchiveInProgress = true
          store.dispatch(D_UNARCHIVE_TRANSPORTATION_OWNER, {
            id: account.id
          }).then(() => {
            this.selectedAccountTab = 'accounts'
            this.searchAccounts(this.selectedAccountTab)
            addAlert({
              message: `Successfully un-archived account ${account.companyName}`,
              type: 'success',
              transient: true
            })
          }).catch((err) => {
            console.log('ERR', err)
            addAlert({
              message: `Error in un-archiving account: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }).finally(() => {
            account.isArchiveInProgress = false
          })
        }
      }
    },
    deleteAccount (account) {
      const idToDelete = prompt(`Type in account id: "${account.id}" (without quotations) to confirm PERMANENTLY deleting this account. You CANNOT undo this.`)
      if (idToDelete === account.id + '') {
        account.isArchiveInProgress = true
        store.dispatch(D_DELETE_TRANSPORTATION_OWNER, {
          id: account.id
        }).then(() => {
          this.selectedUserTab = 'accounts'
          this.searchAccounts(this.selectedUserTab)
          addAlert({
            message: `Permanently deleted account ${account.companyName}`,
            type: 'success',
            transient: true
          })
        }).catch((err) => {
          addAlert({
            message: `Error in permanently deleting account: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }).finally(() => {
          account.isArchiveInProgress = false
        })
      } else {
        alert(`The id entered did not match "${account.id}"`)
      }
    },
    onTabChanged (val) {
      if ((this.selectedAccountTab === 'accounts' && this.totalAccounts) ||
        (this.selectedAccountTab === 'archived-accounts' && this.totalArchivedAccounts)) {
        this.searchAccounts(val)
      }
    },
    onPagination () {
      if ((this.selectedAccountTab === 'accounts' && this.totalAccounts) ||
        (this.selectedAccountTab === 'archived-accounts' && this.totalArchivedAccounts)) {
        console.log('pagination')
        this.searchAccounts(this.selectedAccountTab)
      }
    },
    getTotal (category) {
      if (category === 'archived-accounts') {
        return this.totalArchivedAccounts
      } else {
        return this.totalAccounts
      }
    },
    /**
     *
     * @param {string} category - can be 'archived-accounts'
     * @returns {Promise<void>}
     */
    async searchAccounts (category = undefined) {
      let promises = []
      this.apiInProgress = true
      let retrievedAccounts
      if (category === 'archived-accounts') {
        this.getTotalArchivedAccounts().then((total) => {
          this.totalArchivedAccounts = total.count
        })
        this.getTotalAccounts().then((total) => {
          this.totalAccounts = total.count
        })
        promises.push(this.getArchivedAccounts())
        promises.push(this.getTotalArchivedAccounts())
        const [ accounts, total ] = await Promise.all(promises)
        this.totalArchivedAccounts = total.count
        retrievedAccounts = accounts
      } else {
        if (this.hasUserType('admin')) {
          this.getTotalArchivedAccounts().then((total) => {
            this.totalArchivedAccounts = total.count
          })
        }
        promises.push(this.getAccounts())
        promises.push(this.getTotalAccounts())
        const [ accounts, total ] = await Promise.all(promises)
        this.totalAccounts = total.count
        retrievedAccounts = accounts
      }
      this.accounts = retrievedAccounts.map(o => {
        if (o.dateArchived) {
          o.dateArchivedLabel = this.selectedAccountTab === 'archived-accounts' ? `${formatDate(o.dateArchived)}, ${formatTime(o.dateArchived)}` : null
        }
        o.currentCountryLabel = 'n/a'
        if (this.countries.length) {
          const result = this.countries.find((obj) => obj.value.countryId === o.currentCountryId)
          if (result) {
            o.currentCountryLabel = result.value.countryLabel
          }
        }
        // console.log('this.selectedAccount', this.selectedAccount, o.id)
        o.isSelectedAccount = this.selectedAccount && this.selectedAccount.id === o.id
        o.dateCreatedLabel = `${formatDate(o.dateCreated)}, ${formatTime(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatDate(o.dateUpdated)}, ${formatTime(o.dateUpdated)}` : ''
        o.isEditable = this.hasUserType('admin') || this.hasTransportationRole('OWNER')
        return o
      })
      this.apiInProgress = false
    },
    async getGenericFilter () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        include: 'transportationOwnerProfiles',
        where: {}
      }
      if (sortBy) {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      let countryId
      if (this.selectedCountry && this.selectedCountry.countryId !== 'none') {
        countryId = this.selectedCountry.countryId
      }
      if (countryId) {
        filter.where.currentCountryId = countryId
      }
      if (this.searchKeywords) {
        filter.where.or = generateKeywordsQueryForOr(this.searchKeywords, ['companyName', 'contactName', 'contactEmail', 'contactPhone'])
        const transportationProfiles = await findTransportationProfiles({
          fields: { id: true, transportationOwnerProfiles: true, licensePlateNumber: true },
          where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['licensePlateNumber']) },
          include: 'transportationOwnerProfile'
        })
        if (transportationProfiles.length) {
          filter.where.or.push({ id: { inq: _uniq(transportationProfiles.map(o => o.transportationOwnerProfile.transportationOwnerId)) } })
        }
      }
      return filter
    },
    addArchivedWhere (filter) {
      filter.where.dateArchived = { neq: null }
    },
    async getAccounts () {
      let filter = await this.getGenericFilter()
      return store.dispatch(D_FIND_TRANSPORTATION_OWNERS, filter)
    },
    async getTotalAccounts () {
      let filter = await this.getGenericFilter()
      return store.dispatch(D_FIND_TRANSPORTATION_OWNERS_TOTAL, filter.where)
    },
    async getArchivedAccounts () {
      let filter = await this.getGenericFilter()
      this.addArchivedWhere(filter)
      return store.dispatch(D_FIND_ARCHIVED_TRANSPORTATION_OWNERS, filter)
    },
    async getTotalArchivedAccounts () {
      let filter = await this.getGenericFilter()
      this.addArchivedWhere(filter)
      return store.dispatch(D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL, filter.where)
    }
  }
}
